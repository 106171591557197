import React from 'react'
import { Col, Row } from 'reactstrap'
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export const TourItem = (props) => {
    const { isAuthenticated } = useAuth0();
    const matches = useMediaQuery('(max-width:400px)');
    const handleDelete = async() => {
        await axios.delete(`https://api.lamegenie.com/api/tour/${props.id}`)
        props.refreshTour();
    }

    const mobileTourItem = () => {
        console.log(matches)
        return (
            <div className='tour-item'>


                        <h5>Date: {props.date}</h5>

                        <h5>Venue: {props.venue}</h5>

                        <h5>Info: {props.info}</h5>

                        <h5 className='text-bold'><a href={props.ticketLink} target='blank'>Tickets</a></h5>

            </div>
        )
    }
  return (
    matches ? mobileTourItem() :
    <div className='tour-item'>
        <Row>
            <Col>
                <p>{props.date}</p>
            </Col>
            <Col>
                <p>{props.venue}</p>
            </Col>
            <Col>
                <p>{props.info}</p>
            </Col>
            <Col>
                <p className='text-bold'><a href={props.ticketLink} target='blank'>Tickets</a></p>
            </Col>
            { isAuthenticated && 
            <Col>
                <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
            </Col>
            }
        </Row>
    </div>
  )
}
