import React, { useState } from 'react'
import dayjs from 'dayjs';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const UpdateTour = (props) => {
  const [formData, setFormData] = useState({
    date: dayjs().format('MM/DD/YYYY'),
    venue: '',
    info: '',
    ticketLink: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (formData.title === '' || formData.date === '' || formData.content === '' || formData.date === 'MM/DD/YYYY') {
      alert('Fill it all out, idiot.')
      return;
    }

    const res = await axios.post('https://api.lamegenie.com/api/tour', {
      date: formData.date,
      venue: formData.venue,
      info: formData.info,
      ticketLink: formData.ticketLink
    })
    props.refreshTour();
  }
  const getTour = async () => {
        const res = await axios.get('https://api.lamegenie.com/api/tour')
        console.log(res.data)
    }

    React.useEffect(() => {
        getTour();
    }, [])

  return (
    <div className='news-item'>
      <h5>Add Tour Date</h5>
      <form onSubmit={handleSubmit} className='contact-form'>
      <TextField label="First Name"
        name='firstName'
        sx={{ display: 'none' }}
        onChange={handleChange} />
        <DemoContainer components={['DatePicker', 'DatePicker']}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date"
              value={dayjs(formData.date)}
              variant="filled"
              name="date"
              sx={{ background: 'white', borderRadius: '5px' }}
              onChange={(newValue) => setFormData({ ...formData, date: newValue.format('MM/DD/YYYY') })}
            />
          </LocalizationProvider>
        </DemoContainer>
      <TextField
        label="Venue"
        variant="filled"
        name="venue"
        value={formData.venue}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Info"
        variant="filled"
        name="info"
        value={formData.info}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <TextField
        label="Ticket Link"
        variant="filled"
        name="ticketLink"
        value={formData.ticketLink}
        onChange={handleChange}
        fullWidth
        margin="normal"
        sx={{ background: 'white', borderRadius: '5px' }}
      />
      <Button type="submit" variant="contained" color="primary">
        Add Tour Date
      </Button>
    </form>
  </div>
  )
}
