import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, YouTube, Patreon, Loyalty } from '@mui/icons-material';
import logo from '../img/logo.png'
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const pages = ["Home", "About", "Music", "Band", "Tour", "News", "Videos", "Merch", "Contact"];
  const navigate = useNavigate()
  const [menuItems, setmenuItems] = React.useState(null);
  // function to close and open the tooltip menu
  const openMenuToolTip = (event) => {
     setmenuItems(event.currentTarget);
  };
  
  const closeMenuToolTip = (e) => {
    const value = e.target.innerText.toLowerCase();
    const link = value !== 'home' ? `/${value}` : '/';
      navigate(link)
     setmenuItems(null);
  };

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const socialIcon = (link, icon) => {
    return (
      <IconButton color="inherit" href={link} target="_blank" rel="noopener noreferrer">
        {icon}
      </IconButton>
    )
  }

  const lameNavItem = (link, text) => {
    return (
      <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Button color='inherit'>{text}</Button>
      </Link>
    )
  }

  return (
    <AppBar position="fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
      <Container maxWidth = "xxl">
        <Toolbar>
          <Typography variant="h6" component="div" align='left' sx={{ flexGrow: 1 }}>
            <img src={logo} height="53" width="70" alt="Logo" />
          </Typography>
          <Box sx = {{  display: { xs: "flex", md: "none" } }}>
            <IconButton onClick = {openMenuToolTip} color = "inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={menuItems}
              open={Boolean(menuItems)}
              onClose={closeMenuToolTip}
              color="inherit"
              sx={{ right: 0 }} // Add this line to align the menu to the right
            >
              {pages.map((page) => (
                  <MenuItem key = {page} onClick = {closeMenuToolTip} value={page}>
                    <Typography textAlign = "center"> {page} </Typography>
                  </MenuItem>
              ))}
              {isAuthenticated && 
                <MenuItem key = {'logout'} onClick = {() => logout()}>
                  <Typography textAlign = "center"> Log Out </Typography>
                </MenuItem>
              }
              {socialIcon("https://www.facebook.com/lamegenieband", <Facebook />)}
              {socialIcon("https://twitter.com/lamegenie", <Twitter />)}
              {socialIcon("https://www.instagram.com/lamegenie", <Instagram />)}
              {socialIcon("https://www.youtube.com/lamegenie", <YouTube />)}
              {socialIcon("https://lamegenie.bandcamp.com", <Loyalty />)}
            </Menu>
          </Box>
          <Box sx = {{ flexGrow: 1, display: { xs: "none", md: "flex" } }} color='inherit'>
            {pages.map((page) => (
              <Button
                key = {page}
                onClick = {closeMenuToolTip}
                sx = {{ color: "white", display: "block" }}
                value = {page}
              >
                {page}
              </Button>
            ))}
              {isAuthenticated && 
                <MenuItem key = {'logout'} onClick = {() => logout()}>
                  <Typography textAlign = "center"> Log Out </Typography>
                </MenuItem>
              }
              {socialIcon("https://www.facebook.com/lamegenieband", <Facebook />)}
              {socialIcon("https://twitter.com/lamegenie", <Twitter />)}
              {socialIcon("https://www.instagram.com/lamegenie", <Instagram />)}
              {socialIcon("https://www.youtube.com/lamegenie", <YouTube />)}
              {socialIcon("https://lamegenie.bandcamp.com", <Loyalty />)}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
