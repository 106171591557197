import React from 'react'
import { LamePage } from './LamePage'
import { Col, Row } from 'reactstrap'
import kyle from './img/kyle.png'
import mike from './img/mike.png'
import jeff from './img/jeff.png'

export const Band = () => {
  return (
    <LamePage otherClass='band'>
        <h1>Band</h1>
        <Row>
          <Col>
            <img src={kyle} alt='Kyle' />
            <h5>Kyle // Drums</h5>
          </Col>
          <Col>
            <img src={mike} alt='Mike' />
            <h5>Mike // Bass</h5>
          </Col>
          <Col>
            <img src={jeff} alt='Jeff' />
            <h5>Jeff // Guitar</h5>
          </Col>
        </Row>
    </LamePage>
  )
}
