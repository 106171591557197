import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Email } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useMediaQuery } from '@mui/material';
export const LamePage = (props) => {
  const {title, otherClass} = props;
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Paper className={`lame-jumbotron ${otherClass ?? 'main'}`} sx={{ color: 'white', padding: '32px 0', position: 'relative' }}>
      <div className={`${title ? 'lame-title' : 'lame-content'}`} style={{ zIndex: 1 }}>
        {matches ?
        <div>
          <Grid justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
                {props.children}
            </Grid>
          </Grid>          
        </div>
        :
        <Container>
          <Grid justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={12} sm={8} md={6}>
                {props.children}
            </Grid>
          </Grid>
        </Container>
      }
      </div>
    </Paper>
    </motion.div>
  )
}